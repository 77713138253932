.csr {
  #csr_header {
    font-family: #{$font--text};
    font-size: 12px;
    line-height: 1.5;
    top: 90px;
    a {
      display: block;
    }
  }
  &_table {
    table-layout: auto;
    margin: 0;
    td {
      vertical-align: middle;
      padding: 0 20px 0 20px;
      border: none;
    }
  }
  &_info_box {
    border-right: 1px solid #{$color-red};
  }
  & &_registered_user_info,
  .cust_cart {
    border-right: 1px solid #{$color-red};
  }
  #csr_dragger {
    cursor: move;
    background: #{$color-red};
    border: none;
    width: 40px;
  }
}
