.section-customer-service-contact-us {
  .cs-page__content-item {
    #contact_us {
      .fieldset {
        &.state {
          margin-bottom: 0;
        }
      }
    }
  }
}

.write_a_review__container {
  #pr-write {
    .p-w-r {
      .pr-submit {
        .pr-control-label {
          input[type='checkbox'] {
            left: 0;
            vertical-align: middle;
          }
        }
        .pr-checkbox.pr-has-error {
          .pr-control-label {
            span {
              color: $color-error;
            }
          }
        }
      }
    }
  }
}

.customer-service {
  &__content {
    .cs-contact-info {
      &__title {
        line-height: 30px;
        padding: 20px 0 20px 0;
        height: 90px;
      }
      &__description {
        p:first-child {
          height: 50px;
        }
      }
    }
  }
}

.customer-service {
  select {
    &::-ms-expand {
      display: none;
    }
    -o-appearance: none;
    background-position: 100% center;
    padding: 6px 8px 8px 20px;
  }
  .field-input {
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  .field-input > select {
    width: 125%;
  }
}
